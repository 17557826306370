/*
Mr. Arun Sreekanth Bayanagari 1002024369
Ms. Greeshma Jayanth 1002084507
Mr. Naga Abhilash Chalamcharla 1002148415
Mr. Vineeth Kumar Reddy Gadi 1002066253
MR. Rukesh Sai Kasturi 1002049844
*/

/* Reset some default browser styles */
body,
ul {
    margin: 0;
    padding: 0;
}

/* Apply a background color to the body */
body {
    font-family: Arial, sans-serif;
    background-color: #f0f0f0;
    margin: 0;
    padding: 0;
    background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(/public/utaimage.jpeg);
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center center;
}

/* Dashboard container */
.dashboard {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    min-height: 100vh;
    /* Fill the entire viewport height */
    min-width: 100vw;
}

/* Sidebar */
.sidebar {
    width: 100%;
    background: linear-gradient(to right, #007BFF, #0056b3);
    color: #fff;
    padding: 20px;
    text-align: center;
}

.sidebar ul {
    list-style: none;
    padding: 0;
}

.sidebar li {
    display: inline-block;
    margin-right: 20px;
    cursor: pointer;
    transition: background-color 0.2s;
    font-size: 18px;
}

/* Add a colorful background and change text color on hover */
.sidebar li:hover {
    background-color: #0056b3;
}

.logout_button {
    color: #fff;
}

/* Content area */
.content {
    flex-grow: 1;
    padding: 20px;
    width: 100%;
    text-align: center;
}

/* Add a white background box around the content */

.content-box {
    text-align: center;
    background-color: rgba(255, 255, 255, 0.7);
    /* Semi-transparent white background */
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    /* Drop shadow */
    display: inline-block;
}

/* Style for the welcome text */
.welcome-text {
    font-size: 24px;
    font-style: italic;
    color: #333;
    /* Text color */
    margin: 0;
    padding: 10px;
}

.blur-background {
    filter: blur(5px);
    /* Adjust the blur intensity as needed */
}

.hide-background {
    background-image: none;
}

/* Show the default page by default */
#home {
    display: block;
}

/* Style for headings */
h2 {
    color: #333;
    font-size: 24px;
}

/* Style for paragraphs */
p {
    color: #666;
    font-size: 16px;
}

/* Add a border and rounded corners to sections */
.section {
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 20px;
    margin-bottom: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Add colorful styles for headings */
.section h2 {
    color: #333;
    font-size: 24px;
    margin-bottom: 10px;
    padding: 10px;
    border-radius: 5px;
    color: #fff;
}

/* Add colorful styles for paragraphs */
.section p {
    color: #666;
    font-size: 16px;
    padding: 5px;
    border-radius: 5px;
    color: #fff;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

#contact_body {
    background-color: #f0f0f0;
    font-family: 'Poppins', sans-serif;
}

/* Set a background image for the entire page */
#contact_body {
    background-image: url(/public/utaimage.jpeg);
    background-size: cover;
    background-repeat: no-repeat;
    font-family: 'Poppins', sans-serif;
}


/* Style the container */
#contact_container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

/* Style the heading */
#contact_h1 {
    font-family: 'Playfair Display', serif;
    font-weight: 600;
    font-size: 32px;
    margin-bottom: 20px;
}

/* Style the paragraph */
#contact_p {
    font-size: 18px;
    margin-bottom: 20px;
}

/* Style the labels */
label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
}

/* Style the input fields and textarea */
input[type="text"],
input[type="email"],
input[type="text"],
input[type="password"],
textarea {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

/* Style the submit button */
input[type="submit"] {
    background-color: #007BFF;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 18px;
}

/* Change button color on hover */
input[type="submit"]:hover {
    background-color: #0056b3;
}

/* Style the textarea */
textarea {
    resize: vertical;
}

/* Styles for the modal */
.modal {
    display: none;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
}

.modal-content {
    background-color: #ffffff;
    margin: 15% auto;
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
    max-width: 400px;
    text-align: center;
    border-radius: 5px;
}

.close {
    color: #aaa;
    float: right;
    font-size: 24px;
    font-weight: bold;
    cursor: pointer;
}

.close:hover,
.close:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
}

#popupMessage {
    margin-top: 20px;
    font-size: 18px;
}

/* Add styles for the white card with greater height than width */
.styled-card {
    background-color: #fff;
    width: 70%;
    /* Adjust the width as needed */
    max-width: 600px;
    /* Set a maximum width for responsiveness */
    min-height: 300px;
    /* Set a minimum height to make it taller */
    margin: 0 auto;
    /* Center the card horizontally */
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    font-family: 'Arial', sans-serif;
    /* Change the font */
    line-height: 1.5;
    /* Add spacing between lines */
}

/* Style headings and paragraphs within the card */
.styled-card h2 {
    color: #1c1c1c;
    font-size: 24px;
    margin-bottom: 10px;
    font-family: 'Verdana', sans-serif;
    /* Change the font */
}

.styled-card p {
    color: #242323;
    font-size: 16px;
    font-family: 'Verdana', sans-serif;
    /* Change the font */
}


/* Style the footer text */
.styled-card footer {
    text-align: center;
    background-color: #3b84d2;
    color: white;
    padding: 10px;
}

/* Style for card container */
.card-container {
    display: flex;
    flex-direction: column;
    /* Stack cards vertically */
    overflow-y: scroll;
    width: 80%;
    margin: 0 auto;
    max-height: 800px;
}

/* Style for individual cards */
.card {
    text-align: left;
    width: 100%;
    margin: 10px 0;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    cursor: pointer;
    background-color: #f5f5f5;
    transition: background-color 0.2s;
}

.card:hover {
    background-color: #1c5ed8;
}

/* Popup styles */
.popup {
    display: block;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    border: 1px solid #ccc;
    z-index: 1000;
}

.popup-content {
    text-align: center;
}

.popup button {
    margin: 5px;
    background-color: #007BFF;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
}

.popup button:hover {
    background-color: #0056b3;
}

/* Style for user permissions section */
#user-permissions {
    background-color: #f5f5f5;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-bottom: 20px;
}

#user-permissions h2 {
    font-family: Arial, sans-serif;
}

#user-permissions label {
    font-weight: bold;
    font-family: Arial, sans-serif;
}

#user-permissions select {
    width: 150px;
    padding: 5px;
}

#user-permissions button {
    background-color: #007BFF;
    color: #fff;
    padding: 5px 10px;
    margin-right: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-family: Arial, sans-serif;
}

#admin_button {
    background-color: #007BFF;
    color: #fff;
    padding: 5px 10px;
    margin-right: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-family: Arial, sans-serif;
}

/* Style for data tables */
table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

table th,
table td {
    border: 1px solid #ccc;
    padding: 8px;
    text-align: left;
    font-family: Arial, sans-serif;
}

table th {
    background-color: #f5f5f5;
}

/* Style for links in table data */
table a {
    text-decoration: none;
    font-family: Arial, sans-serif;
}

table a:hover {
    text-decoration: underline;
}

@media (max-width: 768px) {
    body {
        background-position: contain;
        /* Center the background image */
    }

    .container {
        padding: 10px;
    }

    h1 {
        font-size: 28px;
    }

    .sidebar {
        width: 100%;
        text-align: center;
    }

    .sidebar li {
        display: block;
        margin: 10px 0;
    }

    .styled-card {
        width: 90%;
        /* Adjust the width for smaller screens */
        margin: 0 auto;
    }

    .content {
        padding: 10px;
        text-align: center;
        width: 90%;
        /* Adjust the width for smaller screens */
        margin: 0 auto;
    }

    .content-box {
        text-align: center;
        background-color: rgba(255, 255, 255, 0.7);
        /* Semi-transparent white background */
        padding: 20px;
        border-radius: 10px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
        /* Drop shadow */
        display: inline-block;
    }

    #contact_container {
        max-width: 600px;
        margin: 0 auto;
        padding: 20px;
        background-color: #ffffff;
        border-radius: 10px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    }

    /* Style the heading */
    #contact_h1 {
        font-family: 'Playfair Display', serif;
        font-weight: 600;
        font-size: 32px;
        margin-bottom: 20px;
    }

    /* Style the paragraph */
    #contact_p {
        font-size: 12px;
        margin-bottom: 10px;
    }

    .dashboard {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        min-height: 100vh;
        /* Fill the entire viewport height */
        min-width: 100vw;
    }

}

.user-card {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    border: 1px solid #ccc;
    padding: 15px;
    margin-bottom: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.user-info {
    flex: 1;
}

.user-card h3 {
    font-size: 18px;
    margin-bottom: 10px;
}

.user-card p {
    margin: 8px 0;
}

.user-actions {
    display: flex;
    align-items: flex-start;
}

.update-button,
.delete-button {
    margin-left: 10px;
    background-color: #007BFF;
    color: white;
    border: none;
    padding: 8px 16px;
    cursor: pointer;
}

.update-button:hover,
.delete-button:hover {
    background-color: #0056b3;
}

/* Chat button styles */
.chat-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #007BFF;
    color: #fff;
    padding: 10px;
    cursor: pointer;
    border-radius: 5px;
}

.chat-button.active {
    background-color: #0056b3; /* Change color when active */
}

/* Chat window styles */
.chat-window {
    position: fixed;
    bottom: 70px;
    right: 20px;
    width: 300px;
    height: 400px;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 5px;
    overflow: hidden;
}
