/*
Mr. Arun Sreekanth Bayanagari 1002024369
Ms. Greeshma Jayanth 1002084507
Mr. Naga Abhilash Chalamcharla 1002148415
Mr. Vineeth Kumar Reddy Gadi 1002066253
MR. Rukesh Sai Kasturi 1002049844
*/

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
}

body {
  font-family: Arial, sans-serif;
  background-color: #f0f0f0;
  margin: 0;
  padding: 0;
  background-image: linear-gradient(rgba(0,0,0,0.4),rgba(0,0,0,0.4)),url(/public/utaimage.jpeg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.login-form {
  background: #fff;
  width: 500px;
  margin: 65px auto;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-direction: column;
  border-radius: 4px;
  box-shadow: 0 2px 25px rgba(0, 0, 0, 0.2);
}
.login-form h1 {
  padding: 35px 35px 0 35px;
  font-weight: 300;
}
.login-form h4 {
  padding: 35px 35px 0 35px;
  font-weight: 300;
}
.login-form .content {
  padding: 35px;
  text-align: center;
}
.login-form .input-field {
  padding: 12px 5px;
}
.login-form .input-field input {
  font-size: 16px;
  display: block;
  font-family: 'Rubik', sans-serif;
  width: 100%;
  padding: 10px 1px;
  border: 0;
  border-bottom: 1px solid #747474;
  outline: none;
  -webkit-transition: all .2s;
  transition: all .2s;
}
.login-form .input-field input::-webkit-input-placeholder {
  text-transform: uppercase;
}
.login-form .input-field input::-moz-placeholder {
  text-transform: uppercase;
}
.login-form .input-field input:-ms-input-placeholder {
  text-transform: uppercase;
}
.login-form .input-field input::-ms-input-placeholder {
  text-transform: uppercase;
}
.login-form .input-field input::placeholder {
  text-transform: uppercase;
}
.login-form .input-field input:focus {
  border-color: #ff8c00;
}
.login-form a.link {
  text-decoration: none;
  color: #747474;
  letter-spacing: 0.2px;
  text-transform: uppercase;
  display: inline-block;
  margin-top: 20px;
}
.login-form .action {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
          flex-direction: row;
}
.login-form .action button {
  width: 100%;
  border: none;
  padding: 18px;
  font-family: 'Rubik', sans-serif;
  cursor: pointer;
  text-transform: uppercase;
  background: #e8e9ec;
  color: #777;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 0;
  letter-spacing: 0.2px;
  outline: 0;
  -webkit-transition: all .3s;
  transition: all .3s;
}
.login-form .action button:hover {
  background: #d8d8d8;
}
.login-form .action button:nth-child(2) {
  background:#ff8c00;
  color: #fff;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 4px;
}
.login-form .action button:nth-child(1):hover {
  background: #0974e8;
}

.login-form .drop-box {
  padding: 35px 35px 0 35px;
  font-weight: 300;
  font-family: 'Rubik', sans-serif;
  display: inline-block;
  padding: 10px 5px;
  display: -webkit-box;
  text-align:start;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-direction: column;
  border-radius: 2px;
  box-shadow: 0 2px 25px rgba(0, 0, 0, 0.2);
  
}

.login-form .input-field select {
  font-size: 16px;
  font-family: 'Rubik', sans-serif;
  width: 100%;
  padding: 10px 1px;
  border: 0;
  border-bottom: 1px solid #747474;
  outline: none;
  -webkit-transition: all .2s;
  transition: all .2s;
}

.login-form .input-field label {
  font-size: 16px;
  font-family: 'Rubik', sans-serif;
  color: #747474;
  margin-bottom: 5px;
}

.login-form .input-field select:focus {
  border-color: #ff8c00;
}

/* Style the sign-in button */
.signin-button {
  display: inline-block;
  padding: 10px 98px;
  background-color: #007BFF;
  color: #fff;
  text-decoration: none;
  font-size: 18px;
  transition: background-color 0.2s;
}

.signin-button:hover {
  background-color: #0056b3;
}

.submit-button {
  display: inline-block;
  padding: 10px 224px;
  background-color: #007BFF;
  color: #fff;
  text-decoration: none;
  font-size: 18px;
  transition: background-color 0.2s;
}

@media (max-width: 768px) {

  body {
      font-family: Arial, sans-serif;
      background-color: #f0f0f0;
      margin: 0;
      padding: 0;
      background-image: linear-gradient(rgba(0,0,0,0.4),rgba(0,0,0,0.4)),url(/public/utaimage.jpeg);
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
  }
  
  .login-form {
    width: 95%; /* Adjust the width for smaller screens */
    padding: 10px; /* Adjust padding for smaller screens */
  }

}
